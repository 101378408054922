<template>
  <div class="comments">
    <LessonCommentItem 
      v-for="c in comments" 
      v-if="c.status != 'reproved'"
      :key="c.id" 
      :label="c.comment" 
      :autor="c.member" 
      :createdAt="c.created_at" 
      :isMine="c.is_mine"
      :status="c.status" 
      :liked="c.liked" 
      :likes_count="c.likes_count" 
      :id="c.id" 
      :nodes="c.replies"
      :depth="0"
    />
    <div class="create-comment">
      <div class="create-comment__profile">
        <div class="profile__photo"></div>
      </div>
      <textarea 
        class="create-comment__input" 
        type="text"
        :placeholder="$t('player.placeholder_comentario')"
        v-model="comment">
      </textarea>
      <ButtonPlay @click="setComment()" :disabled="!comment">
        Comentar
      </ButtonPlay>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import LessonCommentItem from "./LessonCommentItem.vue"

export default {
  name: "LessonCommentList",
  components: {
    LessonCommentItem
  },
  data() {
    return {
      comment: "",
      id: null,
    }
  },
  computed: {
    ...mapGetters({
      comments: "courses/getLessonComments",
    })
  },
  methods: {
    ...mapActions({
      fetchComments: "courses/actionFetchComments",
      commentCreate: "courses/actionCommentCreate",
      commentEdit: "courses/actionCommentEdit",
    }),
    setComment() {
      if (this.id) {
        this.updateComment()
        return
      }

      this.createComment()
    },
    createComment() {
      this.commentCreate({
        comment: this.comment
      })

      this.comment = ""
    },
    updateComment() {
      this.commentEdit({
        comment: this.comment,
        id: this.id
      })

      this.comment = ""
      this.id = null
      this.fetchComments()
    }
  },
  mounted() {
    this.$root.$on("editComments", (data) => {
      this.id = data.id
      this.comment = data.comentario
    });
  }
}
</script>

<style lang="scss" scoped>

.comments {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.create-comment {
  display: flex;
  gap: 1rem;
}

.create-comment__input {
  all: unset;
  flex: 1;
  
  display: flex;
  min-height: 3rem;
  padding: 0.75rem 0.75rem 2rem 0.75rem;
  align-items: flex-start;
  gap: 0.625rem;
  flex: 1 0 0;

  border-radius: 0.5rem;
  border: 1px solid var(--comment-border);
}

</style>
